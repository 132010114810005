import qs from 'qs';

import { BASE_URLS, BaseUrl } from '../config/base-urls';
import { createApi, QueryFn } from '../config/create-api';

import * as Entities from './models/entities.model';
import { Meta, Params } from './models/parameters.model';
import * as RawEntities from './models/raw-entities.model';
import { STRAPI_COLLECTION_TYPE_ENDPOINTS, STRAPI_POPULATION, STRAPI_SINGLE_TYPE_ENDPOINTS } from './strapi.model';

type InterceptorResponse = Promise<{ data: any; success: true } | { success: false }>;

export const createStrapiClient = ({
    origin,
    headers = {},
    beforeRequest,
    afterRequest,
}: {
    origin: BaseUrl;
    headers?: Record<string, string>;
    beforeRequest?: (request: any) => InterceptorResponse;
    afterRequest?: (response: any) => InterceptorResponse;
}) => {
    const createStrapiQueryFn = <T extends { id: number }, K extends { id: number }, IsSingle extends boolean = false>({
        uri,
        populate,
    }: {
        uri: string;
        populate?: Params<T>['populate'];
    }): QueryFn<Params<T>, { data: IsSingle extends true ? T : T[]; meta: Meta }> => {
        return async (params: Params<T>, { baseFetch }) => {
            const base = BASE_URLS[origin]!;
            const queryString = qs.stringify(
                {
                    ...params,
                    populate: params?.populate ?? populate ?? '*',
                },
                { skipNulls: true },
            );

            const url = `${base}${uri}?${queryString}`;

            if (beforeRequest) {
                const interceptRes = await beforeRequest({ uri, queryString });
                if (interceptRes?.success) {
                    return interceptRes?.data as { data: IsSingle extends true ? T : T[]; meta: Meta };
                }
            }

            const res = await baseFetch<false, { data: K[]; meta: Meta }>({
                url,
                headers,
            });

            if (afterRequest) {
                const afterResult = await afterRequest({ uri, queryString, data: res });
                if (afterResult.success) {
                    return afterResult.data as { data: IsSingle extends true ? T : T[]; meta: Meta };
                }
            }

            return res as { data: IsSingle extends true ? T : T[]; meta: Meta };
        };
    };

    return createApi(build => ({
        get: {
            articles: build({
                cachePrefix: 'articles',
                queryFn: createStrapiQueryFn<Entities.Article, RawEntities.Article>({
                    uri: STRAPI_COLLECTION_TYPE_ENDPOINTS.GET.ARTICLES(),
                    populate: STRAPI_POPULATION.articles,
                }),
            }),
            pages: build({
                cachePrefix: 'pages',
                queryFn: createStrapiQueryFn<Entities.Page, RawEntities.Page>({
                    uri: STRAPI_COLLECTION_TYPE_ENDPOINTS.GET.PAGES(),
                }),
            }),
            flashnotes: build({
                cachePrefix: 'flashnotes',
                queryFn: createStrapiQueryFn<Entities.Flashnote, RawEntities.Flashnote>({
                    uri: STRAPI_COLLECTION_TYPE_ENDPOINTS.GET.FLASHNOTES(),
                    populate: STRAPI_POPULATION.flashnotes,
                }),
            }),
            authors: build({
                cachePrefix: 'authors',
                queryFn: createStrapiQueryFn<Entities.Author, RawEntities.Author>({
                    uri: STRAPI_COLLECTION_TYPE_ENDPOINTS.GET.AUTHORS(),
                    populate: STRAPI_POPULATION.authors,
                }),
            }),
            tags: build({
                cachePrefix: 'tags',
                queryFn: createStrapiQueryFn<Entities.Tag, RawEntities.Tag>({
                    uri: STRAPI_COLLECTION_TYPE_ENDPOINTS.GET.TAGS(),
                    populate: STRAPI_POPULATION.tags,
                }),
            }),
            assetTabs: build({
                cachePrefix: 'assetTabs',
                queryFn: createStrapiQueryFn<Entities.AssetTab, RawEntities.AssetTab>({
                    uri: STRAPI_COLLECTION_TYPE_ENDPOINTS.GET.ASSET_TABS(),
                }),
            }),
            categories: build({
                cachePrefix: 'categories',
                queryFn: createStrapiQueryFn<Entities.Category, RawEntities.Category>({
                    uri: STRAPI_COLLECTION_TYPE_ENDPOINTS.GET.CATEGORIES(),
                }),
            }),
            dashboard: build({
                cachePrefix: 'dashboard',
                queryFn: createStrapiQueryFn<Entities.Dashboard, RawEntities.Dashboard>({
                    uri: STRAPI_COLLECTION_TYPE_ENDPOINTS.GET.DASHBOARD(),
                    populate: STRAPI_POPULATION.dashboard,
                }),
            }),
            dashboards: build({
                cachePrefix: 'dashboards',
                queryFn: createStrapiQueryFn<Entities.Dashboard, RawEntities.Dashboard>({
                    uri: STRAPI_COLLECTION_TYPE_ENDPOINTS.GET.DASHBOARDS(),
                    populate: STRAPI_POPULATION.dashboard,
                }),
            }),
            sponsors: build({
                cachePrefix: 'sponsors',
                queryFn: createStrapiQueryFn<Entities.Sponsor, RawEntities.Sponsor>({
                    uri: STRAPI_COLLECTION_TYPE_ENDPOINTS.GET.SPONSORS(),
                    populate: STRAPI_POPULATION.sponsor,
                }),
            }),
            dataPage: build({
                cachePrefix: 'dataPage',
                queryFn: createStrapiQueryFn<Entities.DataPage, RawEntities.DataPage, true>({
                    uri: STRAPI_SINGLE_TYPE_ENDPOINTS.GET.DATA_PAGE(),
                    populate: STRAPI_POPULATION.dataPage,
                }),
            }),
        },
    }));
};

export type AnyStrapiClient = ReturnType<typeof createStrapiClient>;
