import * as React from 'react';

import { ArticleHit, FlashnoteHit, HitKind } from '@blockworks/platform/api/algolia';
import { Badge, FlexBox, List, Text } from '@blockworks/ui/components';
import { DateFormat } from '@blockworks/ui/date-time';

import { AppRoutes } from '@/services/auth/auth.routes';

import { AvatarFallback } from '../../avatar-fallback/avatar-fallback';
import { SearchHitProps } from '../utils/search.types';

import { ResultHitItemRow } from './item-components/result-hit-item-row';
import { TruncatedTitleText } from './item-components/truncated-title-text';

export const ResearchItem = (props: SearchHitProps<ArticleHit | FlashnoteHit>) => {
    const { onSelect, ...hit } = props;
    let href;

    if (hit.kind === HitKind.ARTICLE) {
        href = AppRoutes.RESEARCH_SLUG.pathname(hit.slug);
    } else if (hit.kind === HitKind.FLASHNOTE) {
        href = AppRoutes.FLASHNOTES_SLUG.pathname(hit.slug);
    }

    return (
        <ResultHitItemRow id={hit.objectID} href={href} onClick={() => onSelect?.(hit)}>
            <List.Col size="content">
                <AvatarFallback alt={hit.title!} src={hit.imageUrl} />
            </List.Col>
            <List.Col size="content" grow>
                <FlexBox h="full" alignItems="start" col>
                    <TruncatedTitleText title={hit.title} />
                    <Text size="xs" color="deselect" lines={1}>
                        <DateFormat date={hit.publishedAt!} />
                    </Text>
                </FlexBox>
            </List.Col>
            <List.Col size="content">
                <FlexBox gap="xs">
                    <FlexBox gap="xs" display={{ initial: 'hidden', sm: 'flex' }}>
                        {hit.kind === HitKind.FLASHNOTE && <Badge intent="primary">Flashnote</Badge>}
                        {hit.tags
                            ?.filter(t => t.name.indexOf(':') === -1)
                            .slice(0, 4)
                            .map(tag => <Badge key={tag.name}>{tag.name}</Badge>)}
                    </FlexBox>
                </FlexBox>
            </List.Col>
        </ResultHitItemRow>
    );
};
