import { SearchHit } from '@blockworks/platform/api/algolia';

import { AssetItem } from '../result-hit-items/asset-item';
import { AuthorItem } from '../result-hit-items/author-item';
import { DashboardItem } from '../result-hit-items/dashboard-item';
import { NewsItem } from '../result-hit-items/news-item';
import { NewsletterItem } from '../result-hit-items/newsletter-item';
import { PageItem } from '../result-hit-items/page-item';
import { PodcastItem } from '../result-hit-items/podcast-item';
import { ProposalItem } from '../result-hit-items/proposal-item';
import { ResearchItem } from '../result-hit-items/research-item';

import { SearchCategoryValues } from './search.enums';
import { SearchCategoryType, SearchHitComponent } from './search.types';

export const SEARCH_CATEGORIES: SearchCategoryType[] = [
    {
        title: 'Recent Searches',
        value: SearchCategoryValues.RECENT_SEARCHES,
        selectable: false,
    },
    {
        title: 'Research',
        value: SearchCategoryValues.RESEARCH,
        shortcut: '/re',
        filters: `kind:"article" OR kind:"flashnote"`,
        hitComponent: ResearchItem as SearchHitComponent<SearchHit>,
        selectable: true,
    },
    {
        title: 'Assets',
        value: SearchCategoryValues.ASSETS,
        shortcut: '/as',
        filters: `kind:"asset"`,
        hitComponent: AssetItem as SearchHitComponent<SearchHit>,
        selectable: true,
    },
    {
        title: 'Podcasts',
        value: SearchCategoryValues.PODCASTS,
        shortcut: '/po',
        filters: `kind:"podcast"`,
        hitComponent: PodcastItem as SearchHitComponent<SearchHit>,
        selectable: true,
    },
    {
        title: 'News',
        value: SearchCategoryValues.NEWS,
        shortcut: '/ne',
        filters: `kind:"news"`,
        hitComponent: NewsItem as SearchHitComponent<SearchHit>,
        selectable: true,
    },
    {
        title: 'Newsletters',
        value: SearchCategoryValues.NEWSLETTERS,
        shortcut: '/nl',
        filters: `kind:"newsletter"`,
        hitComponent: NewsletterItem as SearchHitComponent<SearchHit>,
        selectable: true,
    },
    {
        title: 'Proposals',
        value: SearchCategoryValues.PROPOSALS,
        shortcut: '/pr',
        filters: `kind:"proposal"`,
        hitComponent: ProposalItem as SearchHitComponent<SearchHit>,
        selectable: true,
    },
    {
        title: 'Analysts',
        value: SearchCategoryValues.AUTHORS,
        shortcut: '/an',
        filters: `kind:"author"`,
        hitComponent: AuthorItem as SearchHitComponent<SearchHit>,
        selectable: true,
    },
    // {
    //     title: 'Charts',
    //     value: SearchCategoryValues.CHARTS,
    //     shortcut: '/ch',
    //     filters: `kind:"chart"`,
    //     hitComponent: ChartItem as SearchHitComponent<SearchHit>,
    //     selectable: true,
    // },
    {
        title: 'Dashboards',
        value: SearchCategoryValues.DASHBOARDS,
        shortcut: '/da',
        filters: `kind:"dashboard"`,
        hitComponent: DashboardItem as SearchHitComponent<SearchHit>,
        selectable: true,
    },
    {
        title: 'Pages',
        value: SearchCategoryValues.PAGES,
        shortcut: '/pa',
        filters: `kind:"page"`,
        hitComponent: PageItem as SearchHitComponent<SearchHit>,
        selectable: true,
    },
];
