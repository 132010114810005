import { defineEndpoints } from '../config/define-endpoints';

export const STRAPI_COLLECTION_TYPE_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        ARTICLES: template('/api/articles'),
        FLASHNOTES: template('/api/flashnotes'),
        AUTHORS: template('/api/authors'),
        ASSET_TABS: template('/api/asset-tabs'),
        DASHBOARD: template('/api/dashboard'),
        DASHBOARDS: template(`/api/dashboards`),
        PAGES: template('/api/pages'),
        TAGS: template('/api/tags'),
        CATEGORIES: template('/api/categories'),
        SPONSORS: template('/api/sponsors'),
    },
}));

export const STRAPI_POPULATION = {
    articles: {
        featuredImage: true,
        authors: {
            populate: 'image',
        },
        tags: {
            populate: 'image',
        },
        sponsors: {
            populate: 'logo',
        },
    },
    flashnotes: {
        featuredImage: true,
        authors: {
            populate: 'image',
        },
        tags: {
            populate: 'image',
        },
        sponsors: {
            populate: 'logo',
        },
    },
    sponsor: {
        logo: true,
    },
    dashboard: {
        tag: true,
        source: { populate: '*' },
    },
    authors: {
        image: true,
    },
    tags: {
        image: true,
    },
    dataPage: {
        tabs: {
            populate: {
                dashboard_category: true,
                image: true,
                dashboards: {
                    populate: {
                        tag: true,
                        source: { populate: '*' },
                    },
                },
            },
        },
        spotlight: {
            populate: {
                dashboard_category: true,
                image: true,
                dashboards: {
                    populate: {
                        tag: true,
                        source: { populate: '*' },
                    },
                },
            },
        },
    },
} as const;

export const STRAPI_SINGLE_TYPE_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        DATA_PAGE: template('/api/data-page'),
    },
}));
