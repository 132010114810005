import * as React from 'react';

import { AssetHit } from '@blockworks/platform/api/algolia';
import { FlexBox, List, Price, PriceChangeText, Text } from '@blockworks/ui/components';

import { AvatarFallback } from '@/components/avatar-fallback/avatar-fallback';
import { AppRoutes } from '@/services/auth/auth.routes';
import { getAssetLogo } from '@/utils/logos';

import { SearchHitProps } from '../utils/search.types';

import { ResultHitItemRow } from './item-components/result-hit-item-row';

export const AssetItem = (props: SearchHitProps<AssetHit>) => {
    const { onSelect, ...hit } = props;
    const imageSrc = hit.imageUrl ?? getAssetLogo(hit.code!);
    const href = AppRoutes.ASSETS_SLUG.pathname(hit.code!.toLowerCase());

    return (
        <ResultHitItemRow id={hit.objectID} href={href} onClick={() => onSelect?.(hit)}>
            <List.Col size="content">
                <AvatarFallback alt={hit.title!} src={imageSrc} useFirstLetter />
            </List.Col>
            <List.Col size="content">
                <FlexBox col>
                    <Text size="sm">{hit.title}</Text>
                </FlexBox>
            </List.Col>
            <List.Col size="fit">
                <Text color="deselect">{hit.code}</Text>
            </List.Col>
            {hit.price.usd && (
                <List.Col shrink>
                    <Price price={hit.price.usd} />
                </List.Col>
            )}
            {hit.change.usd24h && (
                <List.Col shrink>
                    <PriceChangeText value={hit.change.usd24h} />
                </List.Col>
            )}
        </ResultHitItemRow>
    );
};
