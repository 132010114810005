import { NewsletterHit } from '@blockworks/platform/api/algolia';
import { getPublicationTitleById } from '@blockworks/platform/api/beehiiv';
import { FlexBox, List, Text } from '@blockworks/ui/components';

import { AppRoutes } from '@/services/auth/auth.routes';
import { getTimeDistance } from '@/utils/date';

import { SearchHitProps } from '../utils/search.types';

import { ResultHitItemRow } from './item-components/result-hit-item-row';
import { TruncatedTitleText } from './item-components/truncated-title-text';

export const NewsletterItem = (props: SearchHitProps<NewsletterHit>) => {
    const { onSelect, ...hit } = props;
    const href = AppRoutes.NEWSLETTER_ID.pathname(hit.publication, String(hit.id));
    const timeAgo = getTimeDistance(hit.publishDate as number);

    return (
        <ResultHitItemRow id={hit.objectID} href={href} onClick={() => onSelect?.(hit)}>
            <List.Col size="fit" grow>
                <FlexBox gap="2xs" col>
                    <TruncatedTitleText title={hit.subjectLine} />
                    <Text size="xs" color="deselect">
                        {timeAgo}
                    </Text>
                </FlexBox>
            </List.Col>
            <List.Col size="content">
                <Text size="xs" color="deselect">
                    {getPublicationTitleById(hit.publication)}
                </Text>
            </List.Col>
        </ResultHitItemRow>
    );
};
