import { defineEndpoints } from '../../config/define-endpoints';
import { DataWithMeta } from '../../config/type-helpers';

type AssetPrice = {
    usd: number;
    eth: number;
    btc: number;
};

type AssetMarketcap = {
    rank: number;
    usd: number;
    usdVolume24h: number;
};

type AssetSupply = {
    circulating: number;
    liquid: number;
    total: number;
};

type AssetChange = {
    usd1h: number;
    usd24h: number;
    btc1h: number;
    btc24h: number;
    eth1h: number;
    eth24h: number;
};

type Ohclv24h = {
    open: number;
    high: number;
    close: number;
    low: number;
    volume: number;
};

enum AssetFieldParam {
    Id = 'id',
    Code = 'code',
    Title = 'title',
    Type = 'type',
    ImageUrl = 'imageUrl',
    Sector = 'sector',
    Sparkline7d = 'sparkline7d',
    Price = 'price',
    MarketCap = 'marketcap',
    Change = 'change',
    IsFavorite = 'isFavorite',
}

const ALL_ASSET_FIELD_PARAMS = Object.values(AssetFieldParam);
const GET_ASSETS_MAX_PAGE_LIMIT = 3000;
const GET_ASSETS_DEFAULT_PAGE_LIMIT = 100;

type GetAssetsRequest = {
    /* Despite the below pageNum param, some existing usages pass a page param */
    page?: number;
    pageNum?: number;
    limit?: number;
    /** Accepts a `sortKey`, with a `sortDir` value e.g. `['price', 'asc']` */
    order?: string[];
    withSparklines?: boolean;
    favorites?: boolean;
    isSupported?: boolean;
    assets?: string[];
    fields?: AssetFieldParam[];
    categories?: string[] | undefined;
    sortDir?: string;
    sortKey?: string;
    type?: string[];
    sectors?: string[];
};

type Sparklines = {
    id: string;
    code: string;
    sparkline7d: number[] | null;
};

type GetAssetSparklineResponse = DataWithMeta<number[]>;

type GetAssetSparklinesResponse = DataWithMeta<Sparklines[]>;

type AssetShape = {
    id: string;
    code: string;
    title: string;
    slug: string | null;
    imageUrl: string;
    tagline: string | null;
    description: string | null;
    sector: string | null;
    category: string | null;
    oldSector: string | null;
    isSupported: boolean;
    type: string | null;
    isFavorite: boolean | null;
    dominance: string | null;
    cgRef: string;
    analystRef: string | null;
    tradingViewRef: string | null;
    sparkline7d: string | null;
    price: AssetPrice;
    marketcap: AssetMarketcap;
    supply: AssetSupply;
    ohlcv24h: Ohclv24h;
    change: AssetChange;
    status?: number;
    error?: string;
};

type GetAssetRequest = { slug: string };
type GetAssetResponse = DataWithMeta<AssetShape>;

type GetAssetsResponse = DataWithMeta<AssetShape[]>;

type UpdateFavoriteAssetRequest = {
    code: string;
};

type GetFavoriteAssetsResponse = DataWithMeta<string[]>;

type AssetAddressesRequest = {
    type?: string[];
    slug: string;
};

type AssetAddressShape = {
    id: string;
    assetId: string;
    address: string;
    chain: string;
    type: string;
    updatedAt: string;
    chainId: string;
    chainTitle: string;
    chainImageUrl: string | null;
    chainType: string | null;
};

type GetAssetAddressesReponse = DataWithMeta<{
    addresses: AssetAddressShape[];
}>;

type AssetLinkShape = {
    id: string;
    assetId: string;
    name: string;
    url: string;
    updatedAt: string;
};

type GetAssetLinksRequest = { slug: string };
type GetAssetLinksResponse = DataWithMeta<AssetLinkShape[]>;

type GetSparklineRequest = { asset: string };
type GetSparklinesRequest = { assetCodes?: string[] };

type GetGlobalsHistoryRequest = { durationInDays: number };
type GlobalMarketDataShape = {
    totalUsdMarketCap: number;
    totalUsdVolume24h: number;
    altcoinUsdMarketCap: number;
    altcoinUsdVolume24h: number;
    stablecoinUsdVolume24h: number;
    stablecoinUsdChange24h: number;
    defiUsdVolume24h: number;
    defiUsdChange24h: number;
    btcDominance: number;
    btcDominanceChange24h: number;
    ethDominance: number;
    ethDominanceChange24h: number;
    updatedAt: number;
    day?: string;
};

type GetGlobalHistoryResponse = DataWithMeta<GlobalMarketDataShape[]>;

const ASSET_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        ASSETS: template('/v2/asset'),
        ASSET: template('/v2/asset/{slug}'),
        FAVORITES: template('/api/v1/favorites'),
        LINKS: template('/api/v1/links/{slug}'),

        SPARKLINE: template('/api/v1/sparkline/{asset}'),
        SPARKLINES: template('/api/v1/sparklines'),

        GLOBALS: template('/api/v1/globals'),
        GLOBALS_HISTORY: template('/api/v1/globals/history/{durationInDays}'),
    },
}));

export type {
    AssetAddressesRequest,
    AssetAddressShape,
    AssetChange,
    AssetMarketcap,
    AssetPrice,
    AssetShape,
    AssetSupply,
    GetAssetAddressesReponse,
    GetAssetLinksRequest,
    GetAssetLinksResponse,
    GetAssetRequest,
    GetAssetResponse,
    GetAssetSparklineResponse,
    GetAssetSparklinesResponse,
    GetAssetsRequest,
    GetAssetsResponse,
    GetFavoriteAssetsResponse,
    GetGlobalHistoryResponse,
    GetGlobalsHistoryRequest,
    GetSparklineRequest,
    GetSparklinesRequest,
    GlobalMarketDataShape,
    Ohclv24h,
    UpdateFavoriteAssetRequest,
};

export {
    ALL_ASSET_FIELD_PARAMS,
    ASSET_ENDPOINTS,
    AssetFieldParam,
    GET_ASSETS_DEFAULT_PAGE_LIMIT,
    GET_ASSETS_MAX_PAGE_LIMIT,
};
