import { createApi } from '../../config/create-api';
import { PaginatedResponseV2 } from '../../config/type-helpers';

import {
    ASSET_ENDPOINTS,
    AssetAddressesRequest,
    AssetShape,
    GetAssetAddressesReponse,
    GetAssetLinksRequest,
    GetAssetLinksResponse,
    GetAssetRequest,
    GetAssetResponse,
    GetAssetSparklineResponse,
    GetAssetSparklinesResponse,
    GetAssetsRequest,
    GetAssetsResponse,
    GetFavoriteAssetsResponse,
    GetGlobalHistoryResponse,
    GetGlobalsHistoryRequest,
    GetSparklineRequest,
    GetSparklinesRequest,
} from './assets.model';
import { AssetV2 } from './assets.model.v2';
import {
    mapAssetRequestV1toV2,
    mapAssetsRequestV1toV2,
    mapAssetV2ToAddressesV1,
    mapAssetV2ToV1,
} from './version-mapper';

const assetsApi = createApi(build => ({
    get: {
        addresses: build<GetAssetAddressesReponse, AssetAddressesRequest>({
            cachePrefix: 'asset-addresses',
            queryFn: async (request, { baseFetch }) => {
                try {
                    const res = await baseFetch<false, any, AssetV2>({
                        url: mapAssetRequestV1toV2(request, ['addresses.chain']),
                    });

                    return {
                        data: {
                            addresses: mapAssetV2ToAddressesV1(res),
                        },
                        _meta: { total: 1, success: true },
                    };
                } catch (error) {
                    const err = error as Error;
                    console.error(err);

                    return { data: undefined as any, _meta: { total: 0, success: false, message: err?.message } };
                }
            },
        }),
        assets: build<GetAssetsResponse, GetAssetsRequest>({
            cachePrefix: 'assets',
            queryFn: async (request, { baseFetch }) => {
                try {
                    const res = await baseFetch<false, any, PaginatedResponseV2<AssetV2>>({
                        url: mapAssetsRequestV1toV2(request),
                    });

                    return {
                        data: res.data.map(mapAssetV2ToV1) as AssetShape[],
                        _meta: { total: res.total, success: true },
                    };
                } catch (error) {
                    const err = error as Error;
                    console.error(err);

                    return { data: undefined as any, _meta: { total: 0, success: false, message: err?.message } };
                }
            },
        }),
        asset: build<GetAssetResponse, GetAssetRequest>({
            cachePrefix: 'asset',
            queryFn: async (request, { baseFetch }) => {
                try {
                    const res = await baseFetch<false, any, AssetV2>({
                        url: mapAssetRequestV1toV2(request),
                    });

                    return {
                        data: mapAssetV2ToV1(res),
                        _meta: { total: 1, success: true },
                    };
                } catch (error) {
                    const err = error as Error;
                    console.error(err);

                    return { data: undefined as any, _meta: { total: 0, success: false, message: err?.message } };
                }
            },
        }),
        favorites: build<GetFavoriteAssetsResponse, void>({
            cachePrefix: 'favorite-assets',
            query: () => ({
                uri: ASSET_ENDPOINTS.GET.FAVORITES(),
            }),
        }),
        links: build<GetAssetLinksResponse, GetAssetLinksRequest>({
            cachePrefix: 'asset-links',
            query: ({ slug }) => ({
                uri: ASSET_ENDPOINTS.GET.LINKS({ slug: slug.toLowerCase() }),
            }),
        }),
        sparkline: build<GetAssetSparklineResponse, GetSparklineRequest>({
            cachePrefix: 'asset-sparkline',
            query: ({ asset }) => {
                return {
                    uri: ASSET_ENDPOINTS.GET.SPARKLINE({ asset }),
                };
            },
        }),
        sparklines: build<GetAssetSparklinesResponse, GetSparklinesRequest>({
            cachePrefix: 'asset-sparklines',
            query: ({ assetCodes }) => {
                return {
                    uri: ASSET_ENDPOINTS.GET.SPARKLINES(),
                    params: {
                        assets: assetCodes,
                    },
                };
            },
        }),
        globals: build({
            cachePrefix: 'globals',
            query: () => {
                return {
                    uri: ASSET_ENDPOINTS.GET.GLOBALS(),
                };
            },
        }),
        /** Get global market history over a given set of days i.e. `durationInDays` */
        globalMarketHistory: build<GetGlobalHistoryResponse, GetGlobalsHistoryRequest>({
            cachePrefix: 'global-market-history',
            query: ({ durationInDays }) => {
                return {
                    uri: ASSET_ENDPOINTS.GET.GLOBALS_HISTORY({
                        durationInDays,
                    }),
                };
            },
        }),
    },
}));

export { assetsApi };
