import '@/assets/styles/global.css';
import '@blockworks/ui/libs/nprogress/styles.css';
import '@blockworks/viz/dashboard-grid/styles.css';

import * as React from 'react';
import { UserProfileTheme } from '@prisma/client';
import { NextPage } from 'next';
import App, { AppContext, AppProps } from 'next/app';
import type { Session } from 'next-auth';

import { HistoryProvider } from '@blockworks/platform/services/history';
import { isServerSide } from '@blockworks/platform/utils';
import { ToastProvider } from '@blockworks/ui/components';
import { inter, maisonNeue } from '@blockworks/ui/fonts';
import { useNProgress } from '@blockworks/ui/libs/nprogress';
import { VisualizationWorkerProvider } from '@blockworks/viz';

import { AppConfigProvider } from '@/context/app-config';
import { AuthProvider } from '@/context/auth';
import { NotificationsProvider } from '@/context/notifications/notifications.provider';
import { QueryProvider } from '@/context/query';
import { ILayoutProps } from '@/interfaces/layout';
import { IMetaProps } from '@/layout/default-meta';
import { GlobalLayout } from '@/layout/global/global-layout';
import { Analytics } from '@/modules/context';
import ResearchConfig from '@/research-config';
import { auth } from '@/services/auth';

type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: React.ReactElement, metaProps?: IMetaProps) => React.ReactNode;
    layoutProps: ILayoutProps['layoutProps'];
};

const defaultMetaProps = {
    title: 'Blockworks Research',
    description: `Blockworks Research is the most powerful research platform in crypto. It brings research, data, tokenomics, governance, models and more into one place.`,
    canonical_url: `${ResearchConfig.SITE_URL}`,
};

// Global App's properties
export type BlockworksResearchAppProps = AppProps & {
    Component: NextPageWithLayout;
    theme: UserProfileTheme;
    session: Session;
};

const BlockworksResearchApp = (appProps: BlockworksResearchAppProps) => {
    useNProgress();

    const { Component, pageProps, session } = appProps;
    // Get meta props from the page component if available and merge with default
    const metaProps = {
        ...defaultMetaProps,
        ...pageProps.meta,
    };

    // Use the layout defined at the page level, if available
    const getLayout = Component?.getLayout
        ? (page: React.ReactElement) => Component.getLayout!(page, metaProps)
        : (page: React.ReactElement) => <GlobalLayout metaProps={metaProps}>{page}</GlobalLayout>;

    return (
        <div className={`${inter.variable} ${maisonNeue.variable} font-body`}>
            <VisualizationWorkerProvider>
                <QueryProvider pageProps={pageProps}>
                    <Analytics meta={metaProps}>
                        <AuthProvider session={session}>
                            <AppConfigProvider>
                                <NotificationsProvider>
                                    <ToastProvider />
                                    {getLayout(
                                        <HistoryProvider>
                                            <Component {...{ ...pageProps }} />
                                        </HistoryProvider>,
                                    )}
                                </NotificationsProvider>
                            </AppConfigProvider>
                        </AuthProvider>
                    </Analytics>
                </QueryProvider>
            </VisualizationWorkerProvider>
        </div>
    );
};

BlockworksResearchApp.getInitialProps = async (appContext: AppContext) => {
    const initialProps = await App.getInitialProps(appContext);
    let session: Session | null = null;

    if (isServerSide()) {
        session = await auth(appContext.ctx);
    }

    return { ...initialProps, session };
};

export default BlockworksResearchApp;
