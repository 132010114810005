import { Avatar, FlexBox, Image, ImageProps, MiniLogo, Text } from '@blockworks/ui';

interface AvatarFallbackProps {
    size?:
        | number
        | {
              w: number;
              h: number;
          };
    alt?: string | undefined | null;
    src?: string | undefined | null;
    circle?: boolean;
    // use the first letter of the alt prop as the avatar if no src is provided
    useFirstLetter?: boolean;
}

export const AvatarFallback = ({ size, alt, circle, src, useFirstLetter }: AvatarFallbackProps) => {
    size = typeof size === 'number' ? { w: size, h: size } : size;

    if (size && src) {
        const borderRadiusProp = (circle ? { borderRadius: 'full' } : {}) as {
            borderRadius: ImageProps['borderRadius'];
        };
        return <Image width={size.w} height={size.h} alt={alt ?? ''} src={src} {...borderRadiusProp} />;
    }

    if (src && alt) {
        return <Avatar size={24} alt={alt} src={src} />;
    }

    return (
        <FlexBox center bgColor={'muted'} borderRadius="full" p={1} className="h-[24px] w-[24px]">
            <DefaultContent alt={alt} useFirstLetter={useFirstLetter} />
        </FlexBox>
    );
};

const DefaultContent = ({ alt, useFirstLetter }: Omit<AvatarFallbackProps, 'src'>) => {
    if (useFirstLetter && alt) {
        return (
            <Text size="lg" color="deselect" className="text-center">
                {alt.charAt(0).toUpperCase()}
            </Text>
        );
    }

    return <MiniLogo size={18} />;
};
