import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const modalStyles = tVariants({
    base: 'fixed top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] bg-surface focus:outline-0 z-0 overflow-hidden',
    slots: {
        shroud: 'fixed bg-surface-interact opacity-60 inset-0 z-0',
    },
    variants: {
        shadow: {
            sm: 'shadow-sm',
            md: 'shadow-md',
            lg: 'shadow-lg',
        },
        borderRadius: {
            sm: 'rounded-sm',
            md: 'rounded-md',
            lg: 'rounded-lg',
            xl: 'rounded-xl',
        },
        ...factoryStyleProps.space,
    },
});

export { modalStyles };
