import * as React from 'react';

import { PageHit } from '@blockworks/platform/api/algolia';
import { FlexBox, List, Text } from '@blockworks/ui/components';

import { AvatarFallback } from '../../avatar-fallback/avatar-fallback';
import { SearchHitProps } from '../utils/search.types';

import { ResultHitItemRow } from './item-components/result-hit-item-row';

export const PageItem = (props: SearchHitProps<PageHit>) => {
    const { onSelect, ...hit } = props;

    return (
        <ResultHitItemRow id={hit.objectID} href={hit.link} onClick={() => onSelect?.(hit)}>
            <List.Col size="content">
                <AvatarFallback />
            </List.Col>
            <List.Col size="content" grow>
                <FlexBox col>
                    <Text size={{ initial: 'xs', md: 'sm' }}>{hit.title}</Text>
                </FlexBox>
            </List.Col>
        </ResultHitItemRow>
    );
};
