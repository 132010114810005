import { Hit } from '@algolia/client-search';

import { AssetChange, AssetPrice } from '../research/assets';

export enum HitKind {
    FLASHNOTE = 'flashnote',
    ARTICLE = 'article',
    ASSET = 'asset',
    AUTHOR = 'author',
    DASHBOARD = 'dashboard',
    NEWS = 'news',
    PODCAST = 'podcast',
    PROPOSAL = 'proposal',
    NEWSLETTER = 'newsletter',
    PAGE = 'page',
}

type CommonFields = {
    id: number | string;
    slug: string;
    title: string;
    name: string;
    imageUrl: string | null;
    date: number | string | null;
    /**
     * Same as `date` - this is technically a duplicate field.
     * But `publishedAt` is the name of the field in strapi. So we want
     * to preserve this field as it is a useful descriptor. and matches our
     * strapi model. We don't *have* to use this as an algolia facet.
     *
     * 3/14/25 - Reverted on previous decision to remove this,
     * and add `publishedAt` back to this model
     */
    publishedAt: number | string | null;
    link: string;
    content: string | null;
    description: string | null;
};

type AuthorData = Pick<CommonFields, 'id' | 'slug' | 'name' | 'title' | 'description' | 'imageUrl'> & {
    kind: HitKind.AUTHOR;
    featured: boolean;
    twitterUrl: string | null;
    linkedinUrl: string | null;
};

type DashboardData = Pick<CommonFields, 'id' | 'slug' | 'title' | 'link' | 'date' | 'publishedAt'> & {
    kind: HitKind.DASHBOARD;
    tabName?: string;
    tag: {
        name: string;
        isAsset: boolean;
        slug: string;
    } | null;
};

type NewsData = Pick<CommonFields, 'id' | 'slug' | 'title' | 'link' | 'date' | 'content' | 'imageUrl'> & {
    kind: HitKind.NEWS;
    modified: number | null;
    type: string;
    author: number;
    excerpt: string;
};

type AssetData = Pick<CommonFields, 'id' | 'slug' | 'title' | 'imageUrl'> & {
    kind: HitKind.ASSET;
    code: string;
    price: AssetPrice;
    change: AssetChange;
    marketcapRank: number;
    marketcapUsd: number;
    tagline: string | null;
    type: string | null;
    sector: string | null;
    category: string | null;
    isSupported: boolean;
};

type ProposalData = Pick<CommonFields, 'id' | 'title' | 'content' | 'date' | 'description'> & {
    kind: HitKind.PROPOSAL;
    assetId: string;
    assetCode: string;
    assetName: string;
    status: string;
    importance: string;
    isDraft: boolean | null;
    categoryId: string;
    author: string;
    comment: string | null;
    discussionUrl: string;
    url: string;
    calldata: string | null;
    txHash: string | null;
    endDate: string | null;
};

type PodcastEpisodeData = Pick<
    CommonFields,
    'id' | 'slug' | 'title' | 'description' | 'content' | 'imageUrl' | 'date' | 'publishedAt'
> & {
    kind: HitKind.PODCAST;
    summary: string;
    podcastId: string;
    podcastSlug: string;
    podcastTitle: string;
    podcastDescription: string;
    podcastSpotifyId: string;
    websiteUrl: string;
    duration: number;
    takeaways: string;
    notes: string;
};

type NewsletterData = Pick<CommonFields, 'id' | 'slug' | 'title' | 'content' | 'date'> & {
    kind: HitKind.NEWSLETTER;
    publication: string;
    publishDate: number | null;
    subtitle: string;
    authors: string[];
    subjectLine: string | null;
    previewText: string | null;
};

type PageData = Pick<CommonFields, 'title' | 'link'> & {
    kind: HitKind.PAGE;
    rank: number;
};

type BaseResearchData = Pick<
    CommonFields,
    'id' | 'slug' | 'title' | 'content' | 'imageUrl' | 'date' | 'publishedAt'
> & {
    keyDetails: string | null;
    topIdea: string | null;
    keyTakeaways: string | null;
    sponsors: string[] | null;
    excerpt: string | null;
    tags: {
        id: number;
        name: string;
        isAsset: boolean;
        slug: string;
        createdAt: string;
        updatedAt: string;
        imageUrl: string | null;
    }[];
    authors: {
        id: number;
        name: string;
        slug: string;
        featured: boolean;
        imageUrl: string | null;
    }[];
};

export type FlashnoteData = BaseResearchData & {
    kind: HitKind.FLASHNOTE;
};

export type ArticleData = BaseResearchData & {
    kind: HitKind.ARTICLE;
};

export type AuthorHit = Hit<AuthorData>;
export type DashboardHit = Hit<DashboardData>;
export type AssetHit = Hit<AssetData>;
export type PodcastEpisodeHit = Hit<PodcastEpisodeData>;
export type NewsHit = Hit<NewsData>;
export type ProposalHit = Hit<ProposalData>;
export type NewsletterHit = Hit<NewsletterData>;
export type ArticleHit = Hit<ArticleData>;
export type FlashnoteHit = Hit<FlashnoteData>;
export type PageHit = Hit<PageData>;

export type SearchHit =
    | AuthorHit
    | DashboardHit
    | NewsHit
    | AssetHit
    | PodcastEpisodeHit
    | ArticleHit
    | FlashnoteHit
    | ProposalHit
    | NewsletterHit
    | PageHit;
