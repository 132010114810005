import { forwardRef } from 'react';

import { cn, PropsOf } from '../../style-system';

type ModalBodyProps = PropsOf<'div'>;

const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>(function ModalBody({ children, className, ...rest }, ref) {
    return (
        <div
            {...rest}
            ref={ref}
            className={cn('w-full scroller h-full flex flex-col flex-grow p-4 items-stretch overflow-auto', className)}
        >
            {children}
        </div>
    );
});

export type { ModalBodyProps };
export { ModalBody };
