import { NewsHit } from '@blockworks/platform/api/algolia';
import { decodeHtmlEntities } from '@blockworks/platform/utils';
import { FlexBox, List, Text } from '@blockworks/ui/components';
import { DateFormat } from '@blockworks/ui/date-time';

import { AvatarFallback } from '../../avatar-fallback/avatar-fallback';
import { SearchHitProps } from '../utils/search.types';

import { ResultHitItemRow } from './item-components/result-hit-item-row';
import { TruncatedTitleText } from './item-components/truncated-title-text';

export const NewsItem = (props: SearchHitProps<NewsHit>) => {
    const { onSelect, ...hit } = props;

    const getTitle = (post: NewsHit) => {
        return decodeHtmlEntities(post.title);
    };

    return (
        <ResultHitItemRow id={hit.objectID} href={hit.link} newTab onClick={() => onSelect?.(hit)}>
            <List.Col size="content">
                <AvatarFallback size={{ w: 40, h: 22.5 }} alt={hit.title} src={hit.imageUrl as string} />
            </List.Col>
            <List.Col size="fit" grow>
                <FlexBox h="full" alignItems="start" col>
                    <TruncatedTitleText title={getTitle(hit)} />
                    <Text size="xs" color="deselect" lines={1}>
                        <DateFormat date={hit.date!} />
                    </Text>
                </FlexBox>
            </List.Col>
            <List.Col size="content" shrink>
                <Text size="xs" color="deselect">
                    {`Blockworks`}
                </Text>
            </List.Col>{' '}
        </ResultHitItemRow>
    );
};
