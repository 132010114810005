import { useRouter } from 'next/router';

import { Menu } from '@blockworks/ui/components';

const assetFilters = [
    {
        children: 'Favorites',
        href: `/assets?favorites=1`, // TODO: change these?
    },
    {
        children: 'Application',
        href: `/assets?type=app`, // TODO: change these?
    },
    {
        children: 'Infrastructure',
        href: `/assets?type=infrastructure`, // TODO: change these?
    },
    {
        children: 'All',
        href: `/assets?supported=0`,
    },
];

const AssetMenu = () => {
    const router = useRouter();
    const { asPath, query } = router;
    return (
        <Menu sub>
            <Menu.List>
                {assetFilters?.map(item => {
                    const onClick = () => {
                        router.push(item.href);
                    };
                    return (
                        <Menu.Item
                            active={`${asPath}?tag=${query.tag}` === item.href || asPath === item.href}
                            key={`assets-${item.href}`}
                            onClick={onClick}
                            id={item.href}
                        >
                            {item.children}
                        </Menu.Item>
                    );
                })}
            </Menu.List>
        </Menu>
    );
};

export default AssetMenu;
