import * as React from 'react';

import { ProposalHit } from '@blockworks/platform/api/algolia';
import { Badge, FlexBox, List, Text } from '@blockworks/ui/components';
import { DateFormat } from '@blockworks/ui/date-time';

import { AvatarFallback } from '@/components/avatar-fallback/avatar-fallback';
import { AppRoutes } from '@/services/auth/auth.routes';
import { getAssetLogo } from '@/utils/logos';

import { SearchHitProps } from '../utils/search.types';

import { ResultHitItemRow } from './item-components/result-hit-item-row';
import { TruncatedTitleText } from './item-components/truncated-title-text';

export const ProposalItem = (props: SearchHitProps<ProposalHit>) => {
    const { onSelect, ...hit } = props;
    const imageSrc = getAssetLogo(hit.assetCode);
    const assetSlug = hit.assetCode ? hit.assetCode.toLowerCase() : '';
    const href = AppRoutes.ASSETS_SLUG_GOVERNANCE_PROPOSAL_ID.pathname(assetSlug, String(hit.id));

    return (
        <ResultHitItemRow id={hit.objectID} href={href} onClick={() => onSelect?.(hit)}>
            <List.Col size="content">
                <AvatarFallback alt={hit.title} src={imageSrc} />
            </List.Col>
            <List.Col size="fit" grow>
                <FlexBox h="full" alignItems="start" col>
                    <TruncatedTitleText title={hit.title} />
                    {hit.endDate && (
                        <Text size="xs" color="deselect" lines={1}>
                            <DateFormat date={hit.endDate} />
                        </Text>
                    )}
                </FlexBox>
            </List.Col>
            <List.Col size="content">{hit.status && <Badge key={hit.status}>{hit.status}</Badge>}</List.Col>
        </ResultHitItemRow>
    );
};
