import _ from 'lodash';
import { stringify } from 'qs';

import { BASE_URLS } from '../../config/base-urls';

import {
    ASSET_ENDPOINTS,
    AssetAddressShape,
    AssetShape,
    GET_ASSETS_DEFAULT_PAGE_LIMIT,
    GetAssetRequest,
    GetAssetsRequest,
} from './assets.model';
import {
    AssetReferenceV2,
    AssetSectorV2,
    AssetV2,
    MarketCapV2,
    MarketV2,
    OhlcvLast24HV2,
    PriceV2,
    SupplyV2,
} from './assets.model.v2';

const mapAssetV2ToV1 = (asset: AssetV2): AssetShape => {
    const marketCap = asset.market_cap || ({} as MarketCapV2);
    const market = asset.markets ? asset.markets[0] || ({} as MarketV2) : ({} as MarketV2);
    const ohlcv24h = asset.ohlcv_last_24_h || ({} as OhlcvLast24HV2);
    const supply = asset.supply || ({} as SupplyV2);
    const price = asset.price || ({} as PriceV2);
    const reference = asset.reference || ({} as AssetReferenceV2);
    const sector = asset.sector || ({} as AssetSectorV2);

    return {
        id: asset.id.toString(),
        code: asset.code ?? '',
        title: asset.title ?? '',
        slug: asset.slug,
        imageUrl: asset.image_url ?? '',
        tagline: asset.tag_line,
        description: asset.description,
        type: asset.type,
        category: asset.category,
        isSupported: asset.is_supported ?? false,
        isFavorite: asset.is_favorite ?? null,
        sector: sector.title ?? null,
        oldSector: asset.legacy_sector,
        dominance: marketCap.dominance?.toString() ?? null,
        cgRef: reference.coingecko ?? '',
        analystRef: reference.ghost_analyst_slug ?? null,
        tradingViewRef: reference.trading_view ?? null,
        sparkline7d: price.sparkline_7_d ?? null,
        change: {
            usd1h: marketCap.percent_change_usd_1_h ?? 0,
            usd24h: marketCap.percent_change_usd_24_h ?? 0,
            btc1h: marketCap.percent_change_btc_1_h ?? 0,
            btc24h: marketCap.percent_change_btc_24_h ?? 0,
            eth1h: marketCap.percent_change_eth_1_h ?? 0,
            eth24h: marketCap.percent_change_eth_24_h ?? 0,
        },
        marketcap: {
            rank: marketCap.rank ?? -1,
            usd: marketCap.usd ?? 0,
            usdVolume24h: market.volume_24_h ?? 0,
        },
        ohlcv24h: {
            open: ohlcv24h.open ?? 0,
            high: ohlcv24h.high ?? 0,
            low: ohlcv24h.low ?? 0,
            close: ohlcv24h.close ?? 0,
            volume: ohlcv24h.volume ?? 0,
        },
        supply: {
            circulating: supply.circulating ?? 0,
            liquid: supply.liquid ?? 0,
            total: supply.total ?? 0,
        },
        price: {
            usd: price.usd ?? 0,
            eth: price.eth ?? 0,
            btc: price.btc ?? 0,
        },
    };
};

const mapAssetV2ToAddressesV1 = (asset: AssetV2): AssetAddressShape[] => {
    return (asset.addresses || []).map(address => ({
        id: address.id.toString(),
        assetId: address.asset_id?.toString() ?? '',
        address: address.address ?? '',
        chain: address.chain?.title ?? '',
        type: address.type ?? '',
        updatedAt: address.updated_at?.toString() ?? '',
        chainId: address.chain_id?.toString() ?? '',
        chainTitle: address.chain?.title ?? '',
        chainImageUrl: address.chain?.image_url ?? null,
        chainType: address.chain?.type ?? '',
    }));
};

const defaultAssetExpand = ['market_cap', 'markets', 'ohlcv_last_24_h', 'supply', 'price', 'reference', 'sector'];

const mapAssetRequestV1toV2 = (request: GetAssetRequest, expand: string[] = defaultAssetExpand): string => {
    const params: Record<string, unknown> = {
        expand: expand.join(','),
    };

    const baseUrl = BASE_URLS['research-api-v2'];
    const path = ASSET_ENDPOINTS.GET.ASSET({ slug: request.slug });
    const queryString = stringify(params, { addQueryPrefix: true });
    return `${baseUrl}${path}${queryString}`;
};
const mapAssetsRequestV1toV2 = (request: GetAssetsRequest, expand: string[] = defaultAssetExpand): string => {
    let query: Record<string, unknown> = {
        market_cap: {
            assetId: {
                $exists: true,
            },
        },
        markets: {
            assetId: {
                $exists: true,
            },
        },
        ohlcv_last_24_h: {
            assetId: {
                $exists: true,
            },
        },
        reference: {
            assetId: {
                $exists: true,
            },
        },
        supply: {
            assetId: {
                $exists: true,
            },
        },
        price: {
            assetId: {
                $exists: true,
            },
        },
    };
    if (request.assets && request.assets.length > 0) {
        query['code'] = {
            $in: request.assets,
        };
    }
    if (request.categories && request.categories.length > 0) {
        query['$or'] = [
            {
                category: {
                    $in: request.categories,
                },
            },
            {
                category: {
                    $exists: false,
                },
            },
        ];
    }
    if (request.type && request.type.length > 0) {
        query['type'] = {
            $in: request.type,
        };
    }

    if (!request.isSupported && (!request.assets || request.assets.length === 0)) {
        query = _.merge(query, {
            market_cap: {
                rank: {
                    $gt: 0,
                },
            },
            markets: {
                volume_24_h: {
                    $gt: 50000,
                },
            },
        });
    }

    const marketQuery = {
        market_cap: {
            usd: {
                $gt: 1000000,
            },
            rank: {
                $gte: 1,
                $lte: 750,
            },
        },
        markets: {
            volume_24_h: {
                $gt: 250000,
            },
        },
    };

    request['order'] ||= ['rank', 'asc'];
    let orderBy: string | undefined;
    switch (request['order'][0]) {
        case 'rank':
            orderBy = 'market_cap.rank';
            break;
        case 'totalSupply':
            orderBy = 'supply.total';
            break;
        case 'priceUsd':
        case 'price':
            orderBy = 'price.usd';
            break;
        case 'marketcap':
            orderBy = 'market_cap.usd';
            break;
        case 'sector':
            orderBy = 'sector.title';
            break;
        case 'volume':
            orderBy = 'markets.volume_24_h';
            query = _.merge(query, marketQuery);
            break;
        case '1h-change':
            orderBy = 'market_cap.percent_change_usd_1_h';
            query = _.merge(query, marketQuery);
            break;
        case '24h-change':
            orderBy = 'market_cap.percent_change_usd_24_h';
            query = _.merge(query, marketQuery);
            break;
        default:
            orderBy = request['order'][0];
            break;
    }

    const params: Record<string, unknown> = {
        count: request.limit || GET_ASSETS_DEFAULT_PAGE_LIMIT,
        page: (request['page'] || request.pageNum || 0) + 1,
        favorites: request.favorites ? true : undefined,
        is_supported: request.isSupported ? true : undefined,
        query: Object.keys(query).length > 0 ? JSON.stringify(query) : undefined,
        order_by: orderBy,
        order_dir: request['order'] ? request['order'][1] : undefined,
        expand: expand.join(','),
    };

    const baseUrl = BASE_URLS['research-api-v2'];
    const path = ASSET_ENDPOINTS.GET.ASSETS();
    const queryString = stringify(params, { addQueryPrefix: true });
    return `${baseUrl}${path}${queryString}`;
};

export { mapAssetRequestV1toV2, mapAssetsRequestV1toV2, mapAssetV2ToAddressesV1, mapAssetV2ToV1 };
