import React, { forwardRef, PropsWithChildren } from 'react';

import { DataTestIdProps } from '../../models/props';
import { cn, safelySpreadDOMProps, TVComponentProps, TVStyleProps } from '../../style-system';
import { LinkBase } from '../link/link.base';
import { ScrollArea } from '../scroll-area/scroll-area';

import { listColStyles, listContainerStyles, listRowStyles, listStyles } from './list.styles';

type ListProps = PropsWithChildren<
    DataTestIdProps &
        TVStyleProps<typeof listStyles> & {
            as?: 'ul' | 'div';
            h?: number | string;
            rowHeight?: number | string;
            name?: string;
            ref?: React.Ref<HTMLDivElement | HTMLUListElement>;
        }
>;

interface ListComponent extends React.ForwardRefExoticComponent<ListProps> {
    Row: typeof ListRow;
    Col: typeof ListCol;
}

const List = forwardRef<HTMLDivElement | HTMLUListElement, ListProps>(function List(
    { as: AsComponent = 'div', h, children, border, name, borderColor, bgColor, rowHeight, ...props },
    ref,
) {
    const rowH = typeof rowHeight === 'number' ? `${rowHeight}px` : rowHeight;
    if (h) {
        return (
            <ScrollArea
                key={`${h}-${rowH}`}
                h={h}
                w="full"
                thumb="sm"
                className={listContainerStyles({
                    border,
                    bgColor,
                    borderColor,
                    noContainerRadius: props.variant === 'compact-alt',
                    className: cn(name, props.className),
                })}
            >
                <AsComponent
                    ref={ref as any}
                    {...safelySpreadDOMProps(props)}
                    style={{ '--row-height': rowH } as Record<string, string>}
                    className={listStyles({
                        divided: props.divided,
                        variant: props.variant,
                        bgColor,
                        p: props.variant === 'adjacent' ? 2 : 0,
                    })}
                >
                    {children}
                </AsComponent>
            </ScrollArea>
        );
    }

    return (
        <AsComponent
            style={{ '--row-height': rowH } as Record<string, string>}
            className={listStyles({ ...props, className: cn(name, props.className) })}
            {...safelySpreadDOMProps(props)}
        >
            {children}
        </AsComponent>
    );
}) as ListComponent;

type ListRowProps = PropsWithChildren<
    DataTestIdProps &
        TVComponentProps<'div' | 'li', typeof listRowStyles> &
        TVStyleProps<typeof listRowStyles> & {
            as?: 'div' | 'li';
            href?: string;
            newTab?: boolean;
        }
>;

const ListRow = forwardRef(
    (
        {
            as: AsComponent = 'div',
            type = 'item',
            newTab,
            hoverable = false,
            href,
            onClick,
            className,
            ...props
        }: ListRowProps,
        ref: unknown,
    ) => {
        const clickable = !!onClick || !!href;

        if (href) {
            return (
                <AsComponent ref={ref as any} {...safelySpreadDOMProps(props)} className="list-none">
                    <LinkBase
                        href={href}
                        target={newTab ? '_blank' : undefined}
                        rel={newTab ? 'noopener noreferrer' : undefined}
                        className={listRowStyles({
                            clickable,
                            type,
                            hoverable: hoverable || clickable,
                            className,
                            ...props,
                        })}
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            onClick?.(e as any);
                        }}
                    >
                        {props.children}
                    </LinkBase>
                </AsComponent>
            );
        }

        return (
            <AsComponent
                ref={ref as any}
                onClick={onClick as any}
                {...safelySpreadDOMProps(props)}
                className={listRowStyles({
                    clickable,
                    type,
                    hoverable: hoverable || clickable,
                    className,
                    ...props,
                })}
            >
                {props.children}
            </AsComponent>
        );
    },
);

ListRow.displayName = 'List.Row';

type ListColProps = PropsWithChildren<
    DataTestIdProps &
        TVStyleProps<typeof listColStyles> & {
            compact?: boolean;
        }
>;

const ListCol = (props: ListColProps) => {
    return (
        <div {...safelySpreadDOMProps(props)} className={listColStyles(props)}>
            {props.children}
        </div>
    );
};

ListCol.displayName = 'List.Col';

List.Col = ListCol;
List.Row = ListRow;

export type { ListColProps, ListProps, ListRowProps };
export { List };
