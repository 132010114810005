import { SessionUser } from 'next-auth';

import { getPermissionLevel, makeAuthRedirectHelper } from '@blockworks/session';
import { AuthRole } from '@blockworks/session/models';

import { AppRoutes } from './auth.routes';

export const getAuthRewrite = (session: SessionUser, path: string) => {
    const userAuthorization = getPermissionLevel(session);
    // If the user is unauthenticated, check if they are coming from an invite link
    if (userAuthorization === AuthRole.UNAUTHENTICATED) {
        const pattern = new URLPattern({ pathname: AppRoutes.INVITE_TOKEN.pattern });
        const match = pattern.exec(path);
        if (match?.pathname?.groups?.token) {
            const params = new URLSearchParams();
            params.set('token', match.pathname.groups.token);
            return { destination: `${AppRoutes.SIGN_IN.pathname}?${params.toString()}` };
        }
    }
    // If the user is subscribed, check if they are coming from an unlocked link
    if (userAuthorization === AuthRole.SUBSCRIBED && path.startsWith('/unlocked/')) {
        return { destination: path.replace('/unlocked/', '/research/') };
    }
    // If the user is not subscribed, check if they are coming from a research link
    if (userAuthorization !== AuthRole.SUBSCRIBED && path.startsWith('/research/')) {
        return { destination: path.replace('/research/', '/unlocked/') };
    }
    return { destination: null };
};

export const getAuthRedirect = makeAuthRedirectHelper({
    [AuthRole.PUBLIC]: {
        allowedPaths: [
            AppRoutes.ERROR.pathname,
            AppRoutes.NOT_FOUND.pathname,
            AppRoutes.FIVE_HUNDRED.pathname,
            AppRoutes.UNLOCKED_SLUG.pattern,
            AppRoutes.GLOSSARY.pathname,
        ],
        skipRedirect: true,
    },
    [AuthRole.UNAUTHENTICATED]: {
        destination: AppRoutes.SIGN_IN.pathname,
        allowedPaths: [
            AppRoutes.SIGN_IN.pathname,
            AppRoutes.SIGN_IN_FORGOT.pathname,
            AppRoutes.SIGN_IN_FORGOT_TOKEN.pattern,
            AppRoutes.SIGN_UP.pathname,
        ],
    },
    [AuthRole.UNSUBSCRIBED]: {
        destination: AppRoutes.SIGN_UP_SELECT_PLAN.pathname,
        allowedPaths: [
            AppRoutes.SIGN_UP_SELECT_PLAN.pathname,
            AppRoutes.SIGN_UP_CONTACT_SALES.pathname,
            AppRoutes.INVITE_TOKEN.pattern,
        ],
    },
    [AuthRole.PENDING_SUBSCRIPTION]: {
        destination: AppRoutes.SIGN_UP_WELCOME.pathname,
        allowedPaths: [
            AppRoutes.SIGN_UP_WELCOME.pathname,
            AppRoutes.SIGN_UP_WELCOME_FAVORITES.pathname,
            AppRoutes.INVITE_TOKEN.pattern,
        ],
    },
    [AuthRole.SUBSCRIBED]: {
        destination: AppRoutes.HOME.pathname,
        allowedPaths: '*',
    },
});
