import { createQuery } from 'react-query-kit';
import { keyBy } from 'lodash-es';

import { STALE_TIME } from '@blockworks/platform/api';
import { AssetFieldParam, assetsApi } from '@blockworks/platform/api/research/assets';
import type { AnyStrapiClient, Dashboard } from '@blockworks/platform/api/strapi';
import { makeStrapiQueries } from '@blockworks/platform/api/strapi/queries';

import { getAssetLogo } from '@/utils/logos';

import { DashboardEntry } from './dashboard-queries.model';

export const createDashboardQueries = (client: AnyStrapiClient) => {
    const useGetAllDashboards = createQuery({
        queryKey: ['all-dashboards'],
        fetcher: async () => {
            const { data: allDashboards } = await client.get.dashboards();

            const assets: string[] = [];
            allDashboards.forEach(({ tag }) => {
                if (tag?.isAsset && !assets.includes(tag?.slug!)) {
                    assets.push(tag.slug!);
                }
            });
            const response = await assetsApi.get.assets({
                assets,
                limit: 50,
                fields: [AssetFieldParam.Title, AssetFieldParam.Code, AssetFieldParam.ImageUrl],
            });

            const data = response?.data;

            const assetDashboards: Record<string, DashboardEntry> = keyBy(
                data.map(asset => ({ ...asset, code: asset.code!, isAsset: true, dashboards: [] as Dashboard[] })),
                'code',
            );
            const nonAssetDashboards: Record<string, DashboardEntry> = {};

            allDashboards.forEach(dashboard => {
                const { tag } = dashboard;
                if (!tag) {
                    nonAssetDashboards.Spotlight = {
                        title: dashboard.title!,
                        code: dashboard.slug!,
                        dashboards: [dashboard],
                        isAsset: false,
                    };
                } else if (assetDashboards[tag.name]) {
                    assetDashboards[tag.name]?.dashboards.push(dashboard);
                } else {
                    nonAssetDashboards[tag.name] = {
                        title: tag.name,
                        code: tag.slug!,
                        dashboards: [dashboard],
                        isAsset: false,
                    };
                }
            });
            return [...Object.values({ ...assetDashboards, ...nonAssetDashboards })];
        },
        staleTime: STALE_TIME.$1Hour,
    });

    const useGetDashboards = createQuery({
        queryKey: ['dashboards'],
        fetcher: client.get.dashboards,
        staleTime: STALE_TIME.$1Hour,
    });

    const useGetDashboardCategories = createQuery({
        queryKey: ['dashboard-categories'],
        fetcher: async () => {
            const { data: datapage } = await client.get.dataPage();

            const groupedByCategory: Record<string, string[]> = {};

            for (const item of datapage?.tabs || []) {
                const category = item?.category;
                if (category) {
                    if (!groupedByCategory[category]) {
                        groupedByCategory[category] = [];
                    }
                    groupedByCategory[category]?.push(item.slug);
                }
            }

            return groupedByCategory;
        },
        staleTime: STALE_TIME.$1Hour,
    });

    const { getDatapage } = makeStrapiQueries({ client, getAssetLogo });

    return {
        getDashboardCategories: {
            use: useGetDashboardCategories,
        },
        getDashboards: {
            use: useGetDashboards,
        },
        getAllDashboards: {
            use: useGetAllDashboards,
        },
        getDatapage,
    };
};
