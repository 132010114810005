import { SessionUser } from 'next-auth';

import { AuthRole } from '../models';

const getPermissionLevel = (user: SessionUser | null | undefined) => {
    if (!user) return AuthRole.UNAUTHENTICATED;
    if (['pending'].includes(user?.subscriptionStatus as string)) return AuthRole.PENDING_SUBSCRIPTION;
    if (['active', 'trialing', 'past_due'].includes(user?.subscriptionStatus as string)) return AuthRole.SUBSCRIBED;
    return AuthRole.UNSUBSCRIBED;
};

export { getPermissionLevel };
