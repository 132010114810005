import { PodcastEpisodeHit } from '@blockworks/platform/api/algolia';
import { FlexBox, List, Text } from '@blockworks/ui/components';
import { DateFormat } from '@blockworks/ui/date-time';

import { AppRoutes } from '@/services/auth/auth.routes';

import { AvatarFallback } from '../../avatar-fallback/avatar-fallback';
import { SearchHitProps } from '../utils/search.types';

import { ResultHitItemRow } from './item-components/result-hit-item-row';
import { TruncatedTitleText } from './item-components/truncated-title-text';

export const PodcastItem = (props: SearchHitProps<PodcastEpisodeHit>) => {
    const { onSelect, ...hit } = props;
    const href = AppRoutes.PODCASTS_SLUG_EPISODE.pathname(hit.podcastSlug, hit.slug);

    return (
        <ResultHitItemRow id={hit.objectID} href={href} newTab onClick={() => onSelect?.(hit)}>
            <List.Col size="content">
                <AvatarFallback size={{ w: 40, h: 22.5 }} alt={hit.title} src={hit.imageUrl as string} />
            </List.Col>
            <List.Col size="fit" grow>
                <FlexBox h="full" alignItems="start" col>
                    <TruncatedTitleText title={hit.title} />
                    <Text size="xs" color="deselect" lines={1}>
                        <DateFormat date={hit.publishedAt!} />
                    </Text>
                </FlexBox>
            </List.Col>
            <List.Col size="content" shrink>
                <Text size="xs" color="deselect">
                    {hit.podcastTitle}
                </Text>
            </List.Col>
        </ResultHitItemRow>
    );
};
