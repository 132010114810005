import { AuthorHit } from '@blockworks/platform/api/algolia';
import { List, Text } from '@blockworks/ui/components';

import { AppRoutes } from '@/services/auth/auth.routes';

import { AvatarFallback } from '../../avatar-fallback/avatar-fallback';
import { SearchHitProps } from '../utils/search.types';

import { ResultHitItemRow } from './item-components/result-hit-item-row';

export const AuthorItem = (props: SearchHitProps<AuthorHit>) => {
    const { onSelect, ...hit } = props;
    const href = AppRoutes.TEAM_SLUG.pathname(hit.slug as string);

    return (
        <ResultHitItemRow id={hit.objectID} href={href} onClick={() => onSelect?.(hit)}>
            <List.Col size="content">
                <AvatarFallback alt={hit.name} src={hit.imageUrl as string} useFirstLetter />
            </List.Col>
            <List.Col size="fit">
                <Text size="sm">{hit.name}</Text>
            </List.Col>
        </ResultHitItemRow>
    );
};
