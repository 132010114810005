export enum BeehiivPublicationIds {
    Daily = 'pub_2d09a047-9004-4638-9d8f-96778cc945a8',
    Weekly = 'pub_3e2b572f-5604-4251-af96-228698616d1f',
    ZeroXResearch = 'pub_0d66d0da-6de0-4f51-b043-4e1ec87d970e',
    Events = 'pub_794f0ed8-b4bf-48ee-828e-7586a5e9575d',
    Webinars = 'pub_461d2572-cd56-493c-8dc2-0580fda18730',
    Empire = 'pub_252bfc8d-0faa-4c84-9c29-22f27253e365',
    Lightspeed = 'pub_8ea337de-9a78-4628-afe5-cca9099a8dea',
    ForwardGuidance = 'pub_4b1f3010-0e25-41c8-8bd3-fdc6db7ecfb9',
    TheDrop = 'pub_b97ffa3c-26b2-4102-b4e6-7524d9196580',
    SupplyShock = 'pub_de00e659-16f9-4c96-a69b-8668669b0a28',
}

export enum BeehiivPublicationSlugs {
    Daily = 'daily',
    Weekly = 'weekly',
    ZeroXResearch = '0xresearch',
    Events = 'events',
    Webinars = 'webinars',
    Empire = 'empire',
    Lightspeed = 'lightspeed',
    ForwardGuidance = 'forwardguidance',
    TheDrop = 'thedrop',
    SupplyShock = 'supplyshock',
}

export enum BeehiivPublicationTitles {
    Daily = 'Blockworks Daily',
    Weekly = 'Blockworks Weekly',
    ZeroXResearch = '0xResearch',
    Events = 'Events',
    Webinars = 'Webinars',
    Empire = 'Empire',
    Lightspeed = 'Lightspeed',
    ForwardGuidance = 'Forward Guidance',
    TheDrop = 'The Drop',
    SupplyShock = 'Supply Shock',
}

export type BeehiivCustomField = {
    name: string;
    value: string | number | boolean;
};

const publicationDataMap = {
    Daily: {
        id: BeehiivPublicationIds.Daily,
        slug: BeehiivPublicationSlugs.Daily,
        title: BeehiivPublicationTitles.Daily,
    },
    Weekly: {
        id: BeehiivPublicationIds.Weekly,
        slug: BeehiivPublicationSlugs.Weekly,
        title: BeehiivPublicationTitles.Weekly,
    },
    ZeroXResearch: {
        id: BeehiivPublicationIds.ZeroXResearch,
        slug: BeehiivPublicationSlugs.ZeroXResearch,
        title: BeehiivPublicationTitles.ZeroXResearch,
    },
    Events: {
        id: BeehiivPublicationIds.Events,
        slug: BeehiivPublicationSlugs.Events,
        title: BeehiivPublicationTitles.Events,
    },
    Webinars: {
        id: BeehiivPublicationIds.Webinars,
        slug: BeehiivPublicationSlugs.Webinars,
        title: BeehiivPublicationTitles.Webinars,
    },
    Empire: {
        id: BeehiivPublicationIds.Empire,
        slug: BeehiivPublicationSlugs.Empire,
        title: BeehiivPublicationTitles.Empire,
    },
    Lightspeed: {
        id: BeehiivPublicationIds.Lightspeed,
        slug: BeehiivPublicationSlugs.Lightspeed,
        title: BeehiivPublicationTitles.Lightspeed,
    },
    ForwardGuidance: {
        id: BeehiivPublicationIds.ForwardGuidance,
        slug: BeehiivPublicationSlugs.ForwardGuidance,
        title: BeehiivPublicationTitles.ForwardGuidance,
    },
    TheDrop: {
        id: BeehiivPublicationIds.TheDrop,
        slug: BeehiivPublicationSlugs.TheDrop,
        title: BeehiivPublicationTitles.TheDrop,
    },
    SupplyShock: {
        id: BeehiivPublicationIds.SupplyShock,
        slug: BeehiivPublicationSlugs.SupplyShock,
        title: BeehiivPublicationTitles.SupplyShock,
    },
};

const beehiivPublicationMap = {
    [BeehiivPublicationIds.Daily]: publicationDataMap.Daily,
    [BeehiivPublicationIds.Weekly]: publicationDataMap.Weekly,
    [BeehiivPublicationIds.ZeroXResearch]: publicationDataMap.ZeroXResearch,
    [BeehiivPublicationIds.Events]: publicationDataMap.Events,
    [BeehiivPublicationIds.Webinars]: publicationDataMap.Webinars,
    [BeehiivPublicationIds.Empire]: publicationDataMap.Empire,
    [BeehiivPublicationIds.Lightspeed]: publicationDataMap.Lightspeed,
    [BeehiivPublicationIds.ForwardGuidance]: publicationDataMap.ForwardGuidance,
    [BeehiivPublicationIds.TheDrop]: publicationDataMap.TheDrop,
    [BeehiivPublicationIds.SupplyShock]: publicationDataMap.SupplyShock,
    [BeehiivPublicationSlugs.Daily]: publicationDataMap.Daily,
    [BeehiivPublicationSlugs.Weekly]: publicationDataMap.Weekly,
    [BeehiivPublicationSlugs.ZeroXResearch]: publicationDataMap.ZeroXResearch,
    [BeehiivPublicationSlugs.Events]: publicationDataMap.Events,
    [BeehiivPublicationSlugs.Webinars]: publicationDataMap.Webinars,
    [BeehiivPublicationSlugs.Empire]: publicationDataMap.Empire,
    [BeehiivPublicationSlugs.Lightspeed]: publicationDataMap.Lightspeed,
    [BeehiivPublicationSlugs.ForwardGuidance]: publicationDataMap.ForwardGuidance,
    [BeehiivPublicationSlugs.TheDrop]: publicationDataMap.TheDrop,
    [BeehiivPublicationSlugs.SupplyShock]: publicationDataMap.SupplyShock,
};

export const getPublicationIdBySlug = (slug: BeehiivPublicationSlugs) =>
    beehiivPublicationMap[slug as keyof typeof beehiivPublicationMap].id;

export const getPublicationSlugById = (id: string) =>
    beehiivPublicationMap[id as keyof typeof beehiivPublicationMap].slug;

export const getPublicationTitleById = (id: string) =>
    beehiivPublicationMap[id as keyof typeof beehiivPublicationMap].title;

export const getPublicationTitleBySlug = (slug: BeehiivPublicationSlugs) =>
    beehiivPublicationMap[slug as keyof typeof beehiivPublicationMap].title;
