import { createQuery } from 'react-query-kit';
import { orderBy, uniqBy } from 'lodash';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';

import { STALE_TIME } from '../../config/stale-time';
import type { AnyStrapiClient } from '../create-strapi-client';
import type { DataPage } from '../models/entities.model';

type MakeStrapiQueriesOptions = {
    client: AnyStrapiClient;
    getAssetLogo?: (slug: string) => string;
};

const makeStrapiQueries = ({ client, getAssetLogo }: MakeStrapiQueriesOptions) => {
    const useGetDatapage = createQuery({
        queryKey: ['strapi-datapage'],
        fetcher: async () => {
            const { data: datapage } = await client.get.dataPage();

            const allCategories = uniqBy(
                [
                    ...datapage.tabs.flatMap(tabs => tabs.dashboard_category || []),
                    ...(datapage?.spotlight ? datapage.spotlight.flatMap(tabs => tabs.dashboard_category || []) : []),
                ],
                'id',
            );
            const orderedCategories = orderBy(allCategories, 'order', 'asc');
            const categories = uniq(datapage?.tabs.map(tab => tab.category)).filter(Boolean);
            const formatDashboard = (tab: DataPage['tabs'][number]) => {
                const tagSlugs =
                    uniq(tab.dashboards.filter(({ tag }) => tag?.isAsset).map(({ tag }) => tag?.slug)) ?? [];
                const assetTag = tagSlugs?.at?.(0);
                const initialDashboard = tab.dashboards.at(0);

                const href = assetTag
                    ? `/analytics/${tab.slug}?dashboard=${initialDashboard?.slug}`
                    : `/analytics/${tab.slug}`;

                return {
                    ...tab,
                    href,
                    assetImage: getAssetLogo?.(assetTag ?? '') ?? null,
                };
            };

            // Temporary exclusion before cutover
            const tabs = datapage?.tabs.filter(tab => tab.slug !== 'spotlight')?.map(formatDashboard);
            const spotlight = datapage?.spotlight?.map?.(formatDashboard);
            const spotlightBySlug = keyBy(spotlight, 'slug');
            const spotlightByAssetCode = keyBy(spotlight, 'assetCode');
            const bySlug = keyBy(tabs, 'slug');
            const byAssetCode = keyBy(tabs, 'assetCode');
            const allBySlug = { ...bySlug, ...spotlightBySlug };

            return {
                category: {
                    bySlug: keyBy(orderedCategories, 'slug'),
                    slugs: orderedCategories.map(cat => cat?.slug).filter(Boolean) as string[],
                },
                categories: categories as string[],
                bySlug,
                spotlightBySlug,
                allBySlug,
                byAssetCode,
                spotlightByAssetCode,
            };
        },
        staleTime: STALE_TIME.$1Hour,
    });

    return {
        getDatapage: { use: useGetDatapage },
    };
};

export { makeStrapiQueries };
