import { tVariants } from '../../style-system';

const multiToggleStyles = tVariants({
    base: 'group/multitoggle rounded-full [&.grouped]:border-surface-divider h-max flex items-center w-max p-0.5',
    slots: {
        icon: 'min-h-[85%]',
        item: 'transition-colors duration-0 h-full rounded-full data-on:bg-surface-muted fill-content-deselected text-content-selected data-on:text-content data-on:fill-content',
    },
    variants: {
        grouped: {
            true: 'grouped',
        },
        intent: {
            primary: {
                base: '[&.grouped]:border-primary-active',
                item: 'hover:text-primary-active-content data-on:bg-primary-active data-on:text-primary-active-content',
            },
        },
        capitalize: {
            true: {
                item: 'capitalize',
                base: 'capitalize',
            },
        },
        size: {
            xs: {
                base: 'text-xxs [&.grouped]:border',
                item: 'px-1 group-[&.grouped]/multitoggle:px-1.5 h-[16px]',
            },
            sm: {
                base: 'text-xs [&.grouped]:border',
                item: 'px-1.5 group-[&.grouped]/multitoggle:px-2 h-[20px]',
            },
            md: {
                base: 'text-xs [&.grouped]:border',
                item: 'px-1.5 py-[2px] group-[&.grouped]/multitoggle:px-2 h-[24px]',
            },
        },
        disabled: {
            true: {
                base: 'opacity-50 cursor-not-allowed',
                item: 'hover:text-content-selected cursor-not-allowed',
            },
        },
        truncateLabels: {
            true: {
                item: 'truncate',
            },
        },
    },
});

export { multiToggleStyles };
